import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout/Layout"
import BlogPostCard from "../../components/BlogPostCard"
import LabelText from "../../components/LabelText"
import Newsletter from "../../components/layout/Newsletter"
import CornerstoneArticles from "../../components/CornerstoneArticles"

const BlogPosts = ({ posts }) => {
  if (!posts) return null

  const cornerstonePosts = posts.filter(
    post => post.node.data?.is_cornerstone_article
  )
  const generalPosts = posts.filter(
    post => !post.node.data?.is_cornerstone_article
  )

  return (
    <section id="blog_posts" className="py-20">
      {cornerstonePosts.length > 0 && (
        <CornerstoneArticles cornerstonePosts={cornerstonePosts} />
      )}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <LabelText className="mb-8 text-gray-700 text-center">
          ALLE WEITEREN ARTIKEL ✍️
        </LabelText>
        <div className="flex flex-wrap flex-col md:flex-row md:-mx-3">
          {generalPosts.map((post, idx) => (
            <div key={idx} className="w-full md:w-1/2 lg:w-1/3 px-3">
              <div className="py-4">
                <BlogPostCard post={post} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

const BlogHomePage = ({ data }) => {
  const doc = data.allPrismicPost.edges.slice(0, 1).pop()
  const posts = data.allPrismicPost.edges

  if (!doc) return null

  return (
    <Layout smoothScrolling={false}>
      <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
        <div className="text-center">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl leading-none font-semibold">
            Deine Quelle über&nbsp;
            <br className="xl:hidden" />
            <span className="text-primary">Conversational Commerce</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Hol dir das Wissen und die Inspiration rund um das Thema
            Conversational Commerce. Lerne wie Chatbots dir helfen können deinen
            E-Commerce Shop zu optimieren.
          </p>
        </div>
      </div>
      <BlogPosts posts={posts} />
      <Newsletter></Newsletter>
    </Layout>
  )
}

export const query = graphql`
  query BlogHomeQuery {
    allPrismicPost(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          id
          uid
          type
          data {
            is_cornerstone_article
            title {
              richText
            }
            date
            hero_image {
              alt
              copyright
              url
              gatsbyImageData
            }
            preview_text {
              richText
            }
            meta_author {
              text
            }
            meta_author_image {
              alt
              copyright
              url
              gatsbyImageData
            }
            article_length {
              text
            }
          }
        }
      }
    }
  }
`

export default BlogHomePage
